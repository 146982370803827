import React from 'react';

import Layout from '../components/layout';
import styled from 'styled-components';
import { Link } from "gatsby"

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  @media (min-width: 575px) {
    max-width: 540px;
  }
  @media (min-width: 767px) {
    max-width: 720px;
  }
  @media (min-width: 991px) {
    max-width: 960px;
  }
  @media (min-width: 1199px) {
    max-width: 1200px;
  }
`;

const EmailSuccess = () => (
    <Container>
    <CenteredLogo>
    
    </CenteredLogo>
    <HeaderWrapper>
    <h1>Thank You</h1>
    <p>Your email has been received.</p>
    <p>
        <Link className="link" to={`/`}>Back to our site</Link>
    </p>
    </HeaderWrapper>
    </Container>
);

const CenteredLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;



const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20%;
  align-items: center;
  padding: 30px;
  background-color: #b3aca6;
  padding-top: 96px;
  @media (max-width: 991px) {
    padding-top: 128px;
  }
`;

export default EmailSuccess;